@import url('https://fonts.googleapis.com/css?family=Libre+Baskerville:400,400i,700|Poppins:500,500i,600,600i|Yeseva+One|Oswald&subset=latin-ext');
body {
    font-family: 'Libre Baskerville', serif;
    font-size: 16px;
}
a, a:active, a:focus, a:hover {
    text-decoration: none;
    outline: none;
    color: #a1123b;
}
.rose {
    color: #a1123b;
}
::-moz-selection {
    background: #a1123b;
    color: #fff;
}
::selection {
    background: #a1123b;
    color: #fff;
}
.mt-15 {
    margin-top: 5rem;
}
.vh-50 {
    height: 50vh!important;
}
.py-15 {
    padding-top: 9rem!important;
    padding-bottom: 9rem!important;
}
.py-xl-15 {
    padding-top: 6rem!important;
    padding-bottom: 6rem!important;
}
@media (min-width:1024px) {
    .py-xl-15 {
        padding-top: 9rem!important;
        padding-bottom: 9rem!important;
    }
}
/* Verify age */
.verify {
    transition: all 700ms ease;
    background-color: #fff;
    z-index: 77777777;
    position: fixed;
    padding: 15px;
    opacity: 1;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
}
.verify.off {
    opacity: 0;
    z-index: -7;
}
.verify input {
    display: inline;
    width: auto;
}
.verify img {
    max-width: 300px;
}
.verify p {
    max-width: 500px;
    margin: auto
}
.verify a {
    color: #a1123b;
}
.verify label {
    font-weight: bold;
    display: inline !important;
    color: #a1123b;
}
.verify h1 {
    color: #a1123b;
    font-weight: normal;
    text-shadow: none;
}
.btn.btn-verify {
    background-color: #a1123b;
    border-color: #a1123b;
    color: #fff !important;
    text-transform: uppercase;
    padding: .3em 2.5em;
    border-radius: 30px;
}
.btn-verify:hover, .btn-verify:active, .btn-verify:focus {
    border-color: #a1123b;
}
/* Fin Verify age */
.overlay_rotate {
    display: none;
    position: fixed;
    top: 0;
    background-color: #a1123b;
    width: 100%;
    height: 100%;
    overflow: hidden !important;
    z-index: 777777777 !important;
}
.iconContainer {
    width: 300px;
    height: 200px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -150px;
    margin-top: -100px;
}
.iconContainer p {
    display: inline-block;
    width: 100%;
    text-align: center;
    margin: 0;
    font-size: 20px;
    opacity: 0;
}
.phone {
    position: relative;
    left: 50%;
    margin-left: -80px;
    width: 0;
    height: 0;
    border: 0 solid #000;
    background: #a9a9a9;
    border-radius: 10px;
    box-sizing: border-box;
    margin-bottom:45px;
}
.phone i {
    text-align: center;
    width: 100%;
    line-height: 120px;
    font-size: 50px;
    color: #454545;
    opacity: 0;
}
@media only screen and (orientation: landscape) and (max-width: 820px) {
    .overlay_rotate {
        display: block;
        z-index:666;
    }
    .overlay_rotate .phone {
        -webkit-animation: sizeIncrease .5s forwards ease,  borderIncrease .5s .5s forwards ease,  rotateLeft .7s 1s forwards ease;
        animation: sizeIncrease .5s forwards ease,  borderIncrease .5s .5s forwards ease,  rotateLeft .7s 1s forwards ease;
    }
    .overlay_rotate .phone i {
        -webkit-animation: fadeIn .5s .8s forwards ease;
        animation: fadeIn .5s .8s forwards ease;
    }
    .overlay_rotate .iconContainer p {
        -webkit-animation: fadeIn .5s 1.3s forwards ease;
        animation: fadeIn .5s 1.3s forwards ease;
    }
}
@-webkit-keyframes sizeIncrease {
    0% {
        width: 10px;
        height: 0;
    }
    50% {
        width: 10px;
        height: 100px;
    }
    100% {
        width: 160px;
        height: 100px;
    }
}
@keyframes sizeIncrease {
    0% {
        width: 10px;
        height: 0;
    }
    50% {
        width: 10px;
        height: 100px;
    }
    100% {
        width: 160px;
        height: 100px;
    }
}
@-webkit-keyframes borderIncrease {
    100% {
        border-width: 10px 20px;
    }
}
@keyframes borderIncrease {
    100% {
        border-width: 10px 20px;
    }
}
@-webkit-keyframes fadeIn {
    100% {
        color: white;
        opacity: 1;
    }
}
@keyframes fadeIn {
    100% {
        color: white;
        opacity: 1;
    }
}
@-webkit-keyframes rotateLeft {
    100% {
        -webkit-transform: rotate(-90deg);
        transform: rotate(-90deg);
    }
}
@keyframes rotateLeft {
    100% {
        -webkit-transform: rotate(-90deg);
        transform: rotate(-90deg);
    }
}
.rose {
    text-transform: inherit;
}
h1, h4, .special-h2   {
    color: #858181;
    text-transform: uppercase;
    font-family: 'Poppins', sans-serif;
    font-size: 26px;
    margin-bottom: 1.3em;
    font-style:normal;
}
h1 span, h4 span, .special-h2  span {
    color: #a1123b;
    text-transform: capitalize;
    font-family: 'Yeseva One', cursive;
    font-size: 1.8em;
    font-style:normal;
}
.cuvee h1 span {
    font-size: 1.5em;
}
h2 {
    font-style: italic;
    color: #fff;
    font-size: 2.1em;
}
h3 {
    font-size: 1.5rem;
    color: #a1123b;
}
.w-150 {
    width: 150px;
}
.paralaxx {
    color: #fff;
}
/* .col-par-5 {
    background-color: #d7d0d0;
} */
.col-par-5 {
    background-color: #f7f7f7;
}

.col-par-5>div {
    width: 25%;
    float: left;
    padding: 3rem 0;
}
.desc {
    opacity: 0;
    transition: all 200ms ease-out;
}
.col-par-5 .item:hover .desc {
    opacity: 1;
}
.col-par-5 img {
    max-width: 200px;
    margin-top: 4rem;
    margin-bottom: 2rem;
    margin-left: auto;
    margin-right: auto;
}
.col-par-5 .div1 {
    background-color: #ccc6c6;
}
.col-par-5 .div2 {
    background-color: #d3cccc;
}
.col-par-5 .div3 {
    background-color: #d7d0d0;
}
.col-par-5 .div4 {
    background-color: #dcd4d4;
}
.col-par-5 .div5 {
    background-color: #e2dada;
}
@media (max-width:1024px) {
    .col-par-5>div {
        width: 50%;
    }
    .desc {
        opacity: 1;
    }
}
@media (max-width:600px) {
    .col-par-5>div {
        width: 100%;
    }
    .desc {
        opacity: 1;
    }
}
p {
    font-size: 16px;
}
.contact-p p {
    font-size: 1.1em;
}
header {
    position: relative;
}
header h1 {
    font-family: 'Poppins', sans-serif;
    font-size: 1.4em;
    letter-spacing: 2px;
    font-weight: 400;
    color: #fff;
    bottom: 0;
    position: absolute;
}
header p {
    position: absolute;
    bottom: 10em;
    font-size: 16px;
    text-align: left;
    max-width: 20%;
    right: 10em;
}
footer {
    font-family: 'Oswald', sans-serif;
    letter-spacing: 1px;
}
@media (max-width:768px) {
    header p {
        display: none;
    }
}
.barre {
    transition: all 300ms ease;
    width: 6px;
    position: absolute;
    left: -20px;
    top: -200px;
}
.barre.right {
    left: auto;
    right: -20px;
}
.barre_right {
    float: right;
    margin-right: -30px;
    width: 6px;
}
.btn-light {
    background-color: transparent;
    border-radius: 0;
    padding: .5em 2.5em;
    border: 2px solid #fff;
    color: #fff;
    text-shadow: 0 0 5px rgba(0,0,0,.3);
    box-shadow: none !important;
    text-transform: uppercase;
    font-family: 'Poppins', sans-serif;
}
.carousel-control-next {
    right: -8vw;
}
.carousel-control-prev {
    left: -8vw;
}
.triman {
    width: 55px;
}
.btn-light:hover {
    background-color: inherit;
    color: inherit;
    border-color: #a1123b;
    color: #a1123b;
}
.nav-home .btn-light:hover {
    background-color: #fff;
}
.btn-rose {
    border-color: #a1123b;
    color: #a1123b;
    text-shadow: none;
}
.nav-home {
    opacity: 0;
    position: absolute;
    z-index: -77;
    transition: opacity 400ms ease;
}
.nav-home img {
    z-index: 777;
    position: relative;
}
.nav-home.on {
    opacity: 1;
    z-index: 77;
}
nav h1, nav h1 span {
    color: #fff !important;
    text-shadow: 0 0 5px rgba(0,0,0,.3);
}
nav div {
    min-height: 150px;
    transition: all 700ms ease;
}
.semi-transparent {
    opacity: .3;
}
.max-logo {
    max-width: 430px;
    height: auto;
}
.transparent {
    pointer-events: none;
    position: relative;
    opacity: 0;
}
.premiere-position {
    position: relative;
    z-index: 7777;
    cursor: pointer;
    top: 3em;
}
.troisieme-position {
    position: relative;
    z-index: 7777;
    cursor: pointer;
    bottom: -3em;
}
.premiere-position h1, .troisieme-position h1 {
    font-size: 15px;
    transition: font .6s ease;
}
.premiere-position .btn, .troisieme-position .btn {
    display: none;
}
.burger, .close-burger {
    cursor: pointer;
    position: fixed;
    max-width: 50px;
    z-index: 77777;
    right: 1.3em;
    top: 1.3em;
    display: none;
    border-radius: 50%;
    background-color: white;
}
.close-burger {
    opacity: 1 !important;
}
.close-burger.on, .burger.on {
    display: block;
}
.menu {
    font-family: 'Libre Baskerville', serif;
    opacity: 0;
    background-color: #fff;
    color: #a1123b;
    position: fixed;
    z-index: -7;
    transition: all 300ms ease;
}
.menu a {
    color: #a1123b;
}
.menu ul {
    list-style-type: none;
    font-size: 22px;
    padding: 0;
}
.menu ul ul {
    font-style: italic;
    color: #7b7177;
    font-size: .9em;
    margin-top: 1em;
    padding: 0;
}
.menu ul ul a {
    color: #7b7177;
}
.menu.on {
    opacity: 1;
    z-index: 7777;
}
video {
    object-fit: cover; 
}
.overlay {
    position: absolute;
    z-index: 7;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0.65+0,0.19+39,0+100 */
    background: -moz-linear-gradient(top, rgba(0,0,0,0.65) 0%, rgba(0,0,0,0.19) 39%, rgba(0,0,0,0) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(0,0,0,0.65) 0%,rgba(0,0,0,0.19) 39%,rgba(0,0,0,0) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(0,0,0,0.65) 0%,rgba(0,0,0,0.19) 39%,rgba(0,0,0,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */
}
.scroll {
    position: fixed;
    cursor: pointer;
    bottom: 2em;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 777;
    max-width: 30px;
}
.section {
    width: 100%;
    height: 100% !important;
    position: fixed;
    z-index: -7;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    transition: opacity 1300ms ease;
    background-size: cover;
}
.section.selected {
    opacity: 1;
    z-index: 7;
}
.section1 {
    background: url('../images/home/bg1.jpg') 50% 50% no-repeat;
    background-size: cover;
}
.section2 {
    background: url('../images/home/bg2.jpg') 50% 50% no-repeat;
    background-size: cover;
}
.section3 {
    background: url('../images/home/bg3.jpg') 50% 50% no-repeat;
    background-size: cover;
}
.section4 {
    background: url('../images/home/bg4.jpg') 50% 50% no-repeat;
    background-size: cover;
}
.section5 {
    background: url('../images/home/bg5.jpg') 50% 50% no-repeat;
    background-size: cover;
}
.patrimoine {
    background: url('../images/patrimoine/header.jpg') 50% 50% no-repeat;
    background-size: cover;
}
.art {
    background: url('../images/art/header.jpg') 50% 50% no-repeat;
    background-size: cover;
}
.bio {
    background: url('../images/bio/header.jpg') 50% 50% no-repeat;
    background-size: cover;
}
.contact {
    background: url('../images/contact/header.png') 50% 50% no-repeat;
    background-size: cover;
}
.cuvees {
    background: url('../images/cuvees/header.jpg') center top no-repeat;
    background-size: cover;
    color: #fff;
}
.cuvee {
    /* background: url('../images/cuvee/bg.jpg') center top repeat-x; */
    background-size: 100% 60vw;
    background-repeat: no-repeat;
}
@media (max-width:600px) {
    .cuvee {
        background-size: 100% 85vw;
    }
}
.patrimoine-paralaxx {
    background: url('../images/patrimoine/patrimoine-paralaxx.jpg') 50% 50% no-repeat;
    background-size: cover;
    min-height: 60vh;
}
.art-paralaxx {
    overflow-x: hidden;
    background: url('../images/art/art-paralaxx.jpg') 50% 50% no-repeat;
    background-size: cover;
    min-height: 60vh;
}
.g-recaptcha { visibility: hidden; height: 0; }
.bio-paralaxx {
    background: url('../images/bio/bio-paralaxx.jpg') 50% 50% no-repeat;
    background-size: cover;
    min-height: 55vh;
}
.notes .desc {
    position: absolute;
    opacity: 0;
    transition: all 300ms ease;
}
footer {
    font-size: .9vw;
}
.notes .desc.on {
    opacity: 1;
}
.ico_outer>div {
    margin-right: 1em;
    width: 80px;
    display: inline-block;
}
.icones {
    position: absolute;
    opacity: 0;
    z-index: -7;
    border: 10px solid #fff;
    width: 80px;
    height: 80px;
    cursor: pointer;
    padding: .5em;
}
.icones.on {
    opacity: 1;
    background-color: #fff;
    z-index: 7;
}
.logo {
    width: 18vw;
    height: auto;
}
.left-apparition {
    left: -50vw;
    position: relative;
    opacity: 0;
    transition: all 1s ease-out;
}
.right-apparition {
    right: -50vw;
    position: relative;
    opacity: 0;
    transition: all 1s ease-out;
}
.left-apparition.on {
    left: 0;
    opacity: 1;
}
.right-apparition.on {
    right: 0;
    opacity: 1;
}
input, textarea, select {
    width: 100%;
    border: 1px solid #b4b4b4;
    color: #000;
    margin-bottom: 1em;
    padding: .8em 1.5em;
}
textarea {
    height: 185px;
}
.abus {
    background-color: #f6f6f6;
    font-size: .9em;
    padding: 7px 4px 4px 4px;
}
footer {
    background-color: #a1123b;
    color: #fff;
}
footer ul {
    list-style-type: none;
}
footer a, footer a:hover, footer a:focus, footer a:active {
    color: #fff;
}
.carousel-indicators {
    position: absolute;
    bottom: 0;
}
#page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}
#contenu {
    flex: 1;
}
@media (max-width:600px) {
    nav div {
        min-height: 50px;
    }
    .premiere-position {
        top: 0em;
    }
    .troisieme-position {
        bottom: 0em;
    }
    .nav-home .mt-15 {
        margin-top: 8.5rem;
    }
    .section4, .section5 {
        background-position: 74% 0%;
    }
    .notes {
        min-height: 200px;
    }
    .icones {
        width: 15%;
        margin-left: 4%;
        border-color: #858181;
        height: 50px;
    }
    .cuvee {
        background-position: 0 0;
    }
    .ico_outer>div {
        margin: 0 .7em;
    }
    .abus {
        font-size: .7em;
    }
    footer .text-right {
        text-align: center !important;
    }
    footer .text-right ul {
       margin-left: 0;
    }
}
@media (min-width:500px) and (max-width:768px) {
    .ico_outer {
        margin-top: -2rem;
    }
}
@media (min-width:1140px) {
    .pt-xl-15 {
        padding-top: 9rem!important;
    }
}
@media (max-width:1024px) {
    .menu ul {
        font-size: 18px;
    }
    .menu ul ul {
        font-size: 14px;
    }
    .barre_right {
        float: right;
        margin-right: -10px;
        width: 4px;
    }
    .icones {
        width: 50px;
        height: 50px;
    }
    .ico_outer>div {
        width: 50px;
    }
}
.block_orientation {
    display: none;
}
@media (max-width:768px) and (orientation: landscape) {
    .block_orientation {
        background-color: #ccc;
        color: #000;
        z-index: 777777777;
        display: block;
        padding: 1em 0;
        font-size: 1.2em;
        position: fixed;
        width: 100vw;
        height: 100vh;
        left: 0;
        text-align: center;
        top: 0;
    }
}
.carousel-indicators li {
    width: 10px !important;
    height: 10px !important;
    border-radius: 100% !important;
    margin: 0 .8em;
}
.my-6 {
    margin-top: 6rem;
    margin-bottom: 6rem;
}
.socials p span {
    font-weight: bold;
    margin: 0 .8em;
}
.socials p span a {
    color: #7b7177;
    font-size: inherit;
}
.socials img, .triman img {
    width: 36px;
    height: auto;
    max-height: 36px;
    margin: 0 .2em;
}
.ico-oeil-rose, .ico-bouche-rose, .ico-nez-rose, .ico-notes-rose {
    transition: opacity 300ms linear;
}
footer .footer-logo, footer div ul {
    width: 125px !important;
    height: auto;
    max-height: 300px;
}
.mobile-show {
    display: none;
}
p > span {
    color: #a1123b;
}
.showMore {
    font-family: 'Poppins', sans-serif;
    padding: .4em .5em .3em .5em;
    font-size: 0.66em;
    text-transform: uppercase;
    color: #ae3255;
    border: 1px solid #ae3255;
}
.showMore-white {
    font-family: 'Poppins', sans-serif;
    padding: .4em .5em .3em .5em;
    font-size: 0.66em;
    text-transform: uppercase;
    color: white;
    border: 1px solid white;
}
.showMore-white:active, .showMore-white:focus, .showMore-white:hover {
    color: white;
}
.salt {
    background: url('../images/cuvees/mobile-salt.jpg') right top no-repeat;
    background-size: contain;
}
.languages {
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    font-size: 1rem;
}
.txt-arrow {
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    font-size: 3.4vw !important;
    color: #a1123b;
}
.back-cuvees {
    font-size: .9vw;
}
.back-cuvees img {
    width: 1vw;
}
.arrow {
    margin: 0 .8em;
}
.revert {
    transform: rotate(180deg);
}
.barre_left_mobile {
    display: none;
}
@media only screen and (max-width: 600px) {
    select, p, input, textarea, ::placeholder {
        font-size: 16px !important;
    }
}
@media only screen and (max-width: 1199px) {
    h2 {
        font-size: 1.3em;
    }
    header h1 {
        font-size: 1.2em;
    }
    .logo {
        width: 32vw;
        height: auto;
    }
    .barre_left_mobile {
        display: inline-block;
        width: 6px;
    }
    .barre {
        display: none;
    }
    .my-6 {
        margin-top: 3rem;
        margin-bottom: 3rem;
    }
    header h1 {
        bottom: 50vh;
    }
    .contact-title {
        bottom: 0;
    }
    .mobile-hide {
        display: none;
    }
    .mobile-show {
        display: block;
    }
    .burger, .close-burger {
        top: auto;
        bottom: 1em;
    }
    .abus {
        text-transform: inherit;
    }
    .col-par-5 img {
        margin-top: unset;
        margin-bottom: unset;
    }
    .mobile-line {
        line-height: 1em;
    }
    .patrimoine-paralaxx {
        background: url('../images/patrimoine/patrimoine-paralaxx.jpg') center center no-repeat;
        background-size: unset;
        min-height: 60vh;
    }
    .art-paralaxx {
        background-size: unset;
        min-height: 60vh;
    }
    .mobile-show a.btn-rose {
        margin-top: 1rem;
    }
    .mobile-nomarge {
        margin-bottom: 0;
    }
    .mobile-cuvee-size {
        max-width: 150px;
        height: auto;
    }
    .bio-paralaxx {
        background: url('../images/mobile/bio/ipad-bio3.jpg') center center no-repeat;
        background-size: cover;
        min-height: 50vh;
        text-align: center;
    }
    .languages {
        font-size: 1.1em;
    }
}
.modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    animation: bounce 0.6s ease;
}
@keyframes bounce{
    from {transform: translateY(100%);}
    to   {transform: translateY(0%);}
}
.modal-dialog {
    position: fixed;
    margin: 0;
    width: 100%;
    height: 100%;
    padding: 0;
}
.modal-content {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 0;
    box-shadow: none;
}
.modal-header {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 60px;
    padding: 20px 12px;
    color: #a1123b;
    border: 0;
}
.modal-body {
    position: absolute;
    top: 60px;
    bottom: 0;
    width: 100%;
    overflow: auto;
}
@media (min-width: 576px) {
    .modal-dialog {
        max-width: 100% !important;
    }
}
@media only screen and (max-width: 767px) {
    footer ul li {
        text-align: center;
    }
    .bio-paralaxx {
        background: url('../images/mobile/bio/mobile-bio3.jpg') center center no-repeat;
        background-size: cover;
        min-height: 85vh;
        text-align: center;
    }
    footer {
        font-size: 1em;
    }
}
.oeil-nez-bouche {
    margin-top: 135px !important;
}
@media (min-width: 1500px) {
    .oeil-nez-bouche {
        margin-top: 205px !important;
    }
}
@media (min-width: 1800px) {
    .oeil-nez-bouche {
        margin-top: 255px !important;
    }
}
@media (min-width: 2050px) {
    .oeil-nez-bouche {
        margin-top: 310px !important;
    }
}
@media (min-width: 2330px) {
    .oeil-nez-bouche {
        margin-top: 88px !important;
    }
    .socials img, .triman img {
        width: 2.1vw;
    }
    footer .footer-logo, footer div ul {
        width: 8vw !important;
    }
    .burger, .close-burger {
        max-width: 3vw;
    }
    .cuvees-app h3 {
        font-size: 1.4vw;
    }
    .abus {
        font-size: .9vw;
    }
}
@media (min-width: 2800px) {
    .oeil-nez-bouche {
        margin-top: 40px !important;
    }
}
@media (min-width: 3300px) {
    .oeil-nez-bouche {
        margin-top: 0 !important;
    }
}
@media (min-width: 3760px) {
    .oeil-nez-bouche {
        margin-top: -40px !important;
    }
}
@media (min-width: 3800px) {
    .mt-15 {
        margin-top: 20rem;
    }
}
@media (max-width: 1366px) {
    nav.mt-15 {
        margin-top: 0;
    }
}
.customPrevBtn, customNextBtn {
    width: 12px;
}
.customNextBtn, .customPrevBtn {
    cursor: pointer;
}
.owl-stage-outer:before {
    position: absolute;
    display: block;
    content: '';
    z-index: 1;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}
.owl-stage-outer:before {
    background: -moz-linear-gradient(left,  rgba(204, 198, 198,1) 0%, rgba(204, 198, 198,0) 35%, rgba(204, 198, 198,0) 65%, rgba(204, 198, 198,1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left,  rgba(204, 198, 198,1) 0%,rgba(204, 198, 198,0) 35%,rgba(204, 198, 198,0) 65%,rgba(204, 198, 198,1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right,  rgba(204, 198, 198,1) 0%,rgba(204, 198, 198,0) 35%,rgba(204, 198, 198,0) 65%,rgba(204, 198, 198,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */
}
.hide {
    display: none;
}
@media screen and (max-width: 767.98px) {
    .verify img {
        max-width: 100px;
    }
}

.mentions div {display: flex; flex-direction: column;}


/** CUVEES CAROUSEL **/
.carousel-cuvees {padding: 0!important;}
.carousel-cuvees .owl-stage-outer:before {display: none!important;}
.carousel-cuvees .item {padding: 3rem 0;height: 100%;}
.carousel-cuvees .owl-nav {margin-top: 30px;}
.carousel-cuvees .owl-prev {margin-right: 10px;outline: none;}
.carousel-cuvees .owl-next {margin-left: 10px;outline: none;}


#contenu > div > div.row.vh-64 > div.col-xl-4.col-md-5.offset-xl-1.text-right.pr-3.mobile-hide > p:nth-child(3) {margin-right: 3rem!important;}
